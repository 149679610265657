import { environment } from '.././../environments/environment';
import { Injectable } from '@angular/core';

const currentDomain = environment.API;
const currentDomainReport = environment.REPORTAPI;

const staticJson = 'assets/jsons/';
const firebase = 'https://fir-python1.firebaseio.com/';

@Injectable()
export class AppSettings {
  public static SESSION_KEY = {
    TOKEN: 'token',
  };
  // public static TOKEN_KEY = {
  //     KEY: 'cDkuhbLSFz8:APA91bFBZruP01fPrD9NKQhBK6TnzRFKPpihVw6yjVUc4cdQy_D-jBBAwNHQRe0T24NlyetENcR4EhYJ6BZeP8xlp_CO1-thM1LwKCFmGcHgLxK-5cwSA8b0kwAERCQxpFoK6FLCJjo5'
  // };
  public static URL: any = {
    WEB_LOGIN_END_POINT: currentDomain + 'web_login_user',
    PROJECT_LIST: currentDomain + 'projectList',
    COUNTRY_LIST: currentDomain + 'get_project_languages',
    LOGIN_URL_END_POINT: currentDomain + 'login_user',
    NEW_LOGIN_END_POINT: currentDomain + 'login_new_user',
    NEW_LOGIN_URL: currentDomain + 'loginURL',
    COUNTRIES_END_POINT: currentDomain + 'fetch_country_table',
    LOGGER_DETAILS_END_POINT: currentDomain + 'logger_details',
    LOGOUT_URL_END_POINT: currentDomain + 'MomentZS/IdentityManager/app/Web/logout.aspx',
    USERINFO_END_POINT: currentDomain + 'get_user_info_v3',

    //reception
    getReceptionList: currentDomain + 'getappData_v3',
    getRefreshList: currentDomain + 'get_new_task_v3',

    //survey
    UpdateMRReply: currentDomain + 'update_mr_reply_v3',
    uploadAudio: currentDomain + 'uploadMrTaskAudio',
    uploadImage: currentDomain + 'uploadMrOpenEndTextImage',
    surveyImage : currentDomain + 'uploadMrTaskImage',
    markSurveySkipped: currentDomain + 'mark_survey_skipped',

    //
    surveyEndType: currentDomain + 'progressStatus_v3',
    tapStream: currentDomain + 'tap_stream_v3',

    //clinic
    updateSelectTest: currentDomain + 'updateSelectTest_v3',
    updateSelectTreatment: currentDomain + 'updateSelectTreatment_v3',
    uploadTumourBoardAudio: currentDomain + 'uploadTumourBoardAudio_v3',
    TreatmentDiscussion : currentDomain + 'TreatmentDiscussion',
    //language-translations
    getLanguageTranslations:currentDomain + 'getLanguageJson',

    //mail
    updateEmailReply: currentDomain + 'updateEmailReply_v3',

    //schedule
    getDefaultTranslations: currentDomain + 'get_default_translations',
    tedxReply: currentDomain + 'tedx_reply_v3',
    chatUpdate: currentDomain + 'chatUpdate_v3',
    data_refresh:currentDomain + 'data_refresh',

    //VoiceMail
    uploadVoiceMail:currentDomain +'uploadVoiceMail_v3',
    //private conversation
    fetchModeratorsWrtProject:currentDomain + 'app_fetch_moderators_wrt_project',

    //logout
    getLogout: currentDomain + 'app_logout',
  };
  public files: any = {
    images: ['.png', '.jpg', '.JPEG'],
    audios: ['.mp3', '.m4a']
  };
}
